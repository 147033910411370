import { Stack } from "@mui/material";
import React, { useState } from "react";
import CommonButtonCode from "./CommonButtonCode";

const IreneVoiceOrChat = ({ setCActive }) => {
  const [tOrV, setTOrV] = useState(true);

  const handleStateChange = (index) => {
    if (index === 0) {
      setTOrV(false);
      setCActive(false);
    } else if (index === 1) {
      setTOrV(true);
      setCActive(true);
    }
  };

  return (
    <>
      <Stack direction={"row"} mt={"10px"} height={42} gap={3}>
        <CommonButtonCode
          i={0}
          handleStateChange={handleStateChange}
          tOrV={tOrV}
          active={tOrV ? false : true}
        />
        <CommonButtonCode
          i={1}
          handleStateChange={handleStateChange}
          tOrV={tOrV}
          active={!tOrV ? false : true}
        />
      </Stack>
    </>
  );
};

export default IreneVoiceOrChat;
