import React from "react";
import "./Login.css"; // Import your CSS file
import { Button, Link, Stack, TextField, Typography } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import Base_Url from "../../api/config";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const initialState = {
    username: "",
    password: "",
  };

  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState(initialState);
  const [locationData, setLocationData] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (username && password !== "") {
        const loginRes = await Base_Url.post(`/a/login`, userDetails);
        if (loginRes.status === 200) {
          toast.success("Successfully Login");
          // Store user data to localStorage
          localStorage.setItem("userId", loginRes.data.user._id);
          localStorage.setItem("email", loginRes.data.user.email);
          setTimeout(() => {
            navigate("/home");
          }, 1100);
        } else {
          toast.error("Some-thing went wrong");
        }
      }
    } catch (error) {}
  };

  const { username, password } = userDetails;

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <Stack className="b_img">
        <Stack direction={"row"}>
          <Stack
            width={"100%"}
            className="left_box"
            bgcolor={"rgba(0, 0,0, 0.65)"}
            alignItems={"center"}
          >
            <Stack width={"500px"}>
              <Stack mt={"87px"} textAlign={"center"} gap={"10px"}>
                <Typography
                  sx={{
                    font: "normal normal normal 60px/73px Averia Serif Libre",
                    color: "#ffffff",
                  }}
                >
                  Vantage Point
                </Typography>
                <Typography
                  sx={{
                    font: "normal normal normal 30px/37px Averia Serif Libre",
                    color: "#ffffff",
                  }}
                >
                  Irene, an Investor Relations Agent
                </Typography>
              </Stack>

              <Stack
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: "30px" }}
                width={"500px"}
              >
                <Stack>
                  <Typography
                    sx={{
                      font: "normal normal normal 15px/17px Averia Serif Libre",
                      color: "#fff",
                    }}
                    textAlign={"justify"}
                  >
                    Username
                  </Typography>
                  <TextField
                    margin="normal"
                    required
                    id="username"
                    value={username}
                    placeholder="username"
                    onChange={handleChange}
                    name="username"
                    autoFocus
                    sx={{
                      mt: "9px",
                      background: "#e8f0fe",
                      borderRadius: "5px",
                    }}
                  />
                </Stack>
                <Stack mt={"20px"}>
                  <Typography
                    sx={{
                      font: "normal normal normal 15px/17px Averia Serif Libre",
                      color: "#fff",
                    }}
                    textAlign={"justify"}
                  >
                    Password
                  </Typography>

                  <TextField
                    margin="normal"
                    sx={{
                      mt: "9px",
                      background: "#e8f0fe",
                      borderRadius: "5px",
                    }}
                    required
                    name="password"
                    value={password}
                    onChange={handleChange}
                    placeholder="password"
                    type="password"
                    id="password"
                  />
                </Stack>
                <Stack mt={"20px"}>
                  <Link
                    href="/forgetpsw"
                    sx={{
                      font: " normal normal normal 15px/17px Averia Serif Libre",
                      color: "#fff",
                      textDecoration: "underline",
                    }}
                    textAlign={"end"}
                  >
                    Forgot Password?
                  </Link>
                </Stack>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: "27px",
                    p: "11px 0px",
                    font: "normal normal normal 20px/25px Averia Serif Libre",
                    border: "2px solid #F2F5F8",
                    borderRadius: "5px",
                    background: "#000",
                    "&:hover": {
                      background: "#000",
                    },
                  }}
                >
                  Sign in
                </Button>
              </Stack>
              <Typography textAlign={"center"} mt={"30px"} mb={"20px"}>
                {" "}
                <Link
                  sx={{
                    textDecoration: "none",
                    color: "#fff",
                    font: "normal normal normal 15px/17px Averia Serif Libre",
                  }}
                >
                  Are you new?{" "}
                  <Link
                    sx={{ color: "#fff", textDecoration: "underline" }}
                    href="/register"
                  >
                    Create an Account
                  </Link>
                </Link>
              </Typography>
            </Stack>
            <Stack width={"100%"} marginTop={"100px"} ml={"20px"} mb={"20px"}>
              <Typography
                sx={{ font: "italic normal 300 15px/19px Averia Serif Libre" }}
                color={"#fff"}
                width={"385px"}
              >
                Vantage’s Irene Agent is powered by BEBA, an infrastructure to
                build a better AI agent
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Login;
