import React from "react";
import gc from "./gc.module.css";
import ireren from "./irene.svg";
const IreneAnimate = ({ callStart }) => {
  return (
    <div
      className={`${gc["irene-border"]} ${
        callStart ? gc["irene-border-active"] : ""
      }`}
    >
      <img src={ireren} alt="img" width="100%" />
    </div>
  );
};

export default IreneAnimate;
