import { IconButton, InputBase, Stack } from "@mui/material";
import React from "react";
import LoaderAtInput from "../Loader/LoaderAtInput";

export default function TextInputArea({
  userQuestion,
  handleInputChange,
  onClickCallConverseAI,loader
}) {
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        border={"1.5px solid #A71553"}
        borderRadius={"5px"}
        width={"100%"}
      >
        <InputBase
          sx={{
            border: "none",
            outline: "none",
            width: "100%",
            height: "32px",
            padding: "10px",
          }}
          placeholder="What do you want to know about VantagePoint?"
          value={userQuestion}
          onChange={handleInputChange}
        />

        <Stack backgroundColor={"#A71553"} padding={"5px"} border={"none"}>
          <IconButton onClick={() => onClickCallConverseAI()}>
            {!loader ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.1716 6.9999L6.8076 1.63589L8.2218 0.22168L16 7.9999L8.2218 15.778L6.8076 14.3638L12.1716 8.9999H0V6.9999H12.1716Z"
                  fill="white"
                />
              </svg>
            ) : (
              <LoaderAtInput />
            )}
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
}
