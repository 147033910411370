import {
  CALL_START,
  LOADER_STATE,
  LOAD_USER_AI_CHAT,
  CALL_TALK_START,
  INITIAT_GREENTINGS,
  IRENE_LISTENING,STOP_TRANSCRIPTION,MASTER_AITALK_BACK, IRENE_LISTENING_SOCKET
} from "./actiontypes";

const initialState = {
  callStart: false,
  language: null,
  loader: true,
  chatLoading: false,
  chats: [], // This will hold the chat data
};

const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALL_START:
      return {
        ...state,
        callStart: action.payload,
      };
    case CALL_TALK_START:
      return {
        ...state,
        callTalkStart: action.payload,
      };
    case INITIAT_GREENTINGS:
      return {
        ...state,
        greentings: action.payload,
      };
    case IRENE_LISTENING:
      return {
        ...state,
        ireneListening: action.payload,
      };
    case IRENE_LISTENING_SOCKET:
      return {
        ...state,
        ireneListeningSocket: action.payload,
      };
    case MASTER_AITALK_BACK:
      return {
        ...state,
        isMasterTalkBack: action.payload,
      };
    case STOP_TRANSCRIPTION:
      return {
        ...state,
        forceStopTransctions: action.payload,
      };
    case LOADER_STATE:
      return {
        ...state,
        chatLoading: action.payload,
      };
    case LOAD_USER_AI_CHAT: // Handle loading of user-AI chats
      return {
        ...state,
        chats: action.payload, // Assuming payload contains the array of chats
      };
    default:
      return state;
  }
};

export default stateReducer;
