import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import CallIreneIcon from "../icons/CallIreneIcon";
import style from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  callTalkState,
  callState,
  initiatGreentings,
  initiatIreneListeningMode,
  forceStopTranscription,
  loader,
} from "../../redux/action/stateAction/stateAction";
import EndCallIcon from "../icons/EndCallIcon";
export default function CallIreanButton() {
  const dispatch = useDispatch();

  const { callTalkStart, callStart } = useSelector(
    (state) => state.stateReducer
  );

  const handleCall = () => {
    dispatch(callTalkState(callTalkStart ? false : true));
    dispatch(callState(false));

    if (!callTalkStart) {
      dispatch(initiatGreentings(!callTalkStart ? true : false));
      dispatch(initiatIreneListeningMode(false));
      dispatch(forceStopTranscription(!callTalkStart ? false : true));
    } else {
      dispatch(forceStopTranscription(!callTalkStart ? false : true));
      dispatch(loader(false));
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          handleCall();
        }}
        className={callTalkStart || callStart ? `${style.r}` : `${style.n}`}
        startIcon={
          callTalkStart || callStart ? <EndCallIcon /> : <CallIreneIcon />
        }
        sx={{
          textTransform: "none",
          borderRadius: "5px",
          marginTop: "40px",
          color: "white",
        }}
      >
        <span
          style={{
            fontFamily: "Inter",
            fontWeight: 600,
            fontSize: "18px",
          }}
        >
          {!(callTalkStart || callStart) ? `Call Irene` : `End Call`}
        </span>
      </Button>

      {(callTalkStart || callStart) && (
        <>
          <Stack mt={"10px"} direction={"column"} gap={2}>
            <Typography className={style.t1}>
              Be sure to enable your microphone in your browser.{" "}
            </Typography>
            <Typography className={style.t1}>
              Remember: Irene can make mistakes. consider checking important
              information.{" "}
            </Typography>
            <Stack
              direction={"row"}
              gap={2}
              alignContent={"center"}
              alignItems={"center"}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5676 2.73096H2.43239C1.7729 2.73096 1.23828 3.26558 1.23828 3.92506V14.075C1.23828 14.7345 1.7729 15.2691 2.43239 15.2691H15.5676C16.2271 15.2691 16.7617 14.7345 16.7617 14.075V3.92506C16.7617 3.26558 16.2271 2.73096 15.5676 2.73096Z"
                  stroke="#7E7E7A"
                  strokeWidth="1.71"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.23828 4.22363L8.23575 8.43195C8.45032 8.55792 8.72074 8.6269 8.99998 8.6269C9.27922 8.6269 9.54964 8.55792 9.76421 8.43195L16.7617 4.22363"
                  stroke="#7E7E7A"
                  strokeWidth="1.71"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <Typography className={style.t1}>Send us feedback</Typography>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
}
