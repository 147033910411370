import { Button, Stack } from "@mui/material";
import React from "react";
import TextChatIcon from "../icons/TextChatIcon";
import ChatIcon from "../icons/ChatIcon";

const CommonButtonCode = ({ active, handleStateChange, i }) => {
  return (
    <>
      <Stack
        justifyContent={"center"}
        width={160}
        bgcolor={active ? "white" : "#A0A0A0"}
        borderRadius={1.5}
        border={
          active
            ? "2.5px solid rgba(167, 21, 83, 1)"
            : "2.5px solid transparent"
        }
      >
        <Button
          direction={"row"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
            padding: "10px",

            color: active ? "#A71553" : "white",
            borderRadius: 8,
          }}
          onClick={() => handleStateChange(i)}
          width={"100% !important"}
        >
          {i === 1 ? <TextChatIcon active={active} /> : <ChatIcon />}

          {i === 1 ? `Text Chat` : `Voice Chat`}
        </Button>
      </Stack>
    </>
  );
};

export default CommonButtonCode;
