import { Stack } from "@mui/material";
import React from "react";

const Pdq = ({ setPdqs }) => {
  return (
    <>
      <Stack gap={1} marginTop={"35px"}>
        <div
          style={{
            fontFamily: "Raleway",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "20px",
            marginBottom: "15px",
            letterSpacing: "0.25px",
          }}
        >
          Questions Investors Ask
        </div>

        <div
          style={{
            color: "white",
            fontFamily: "Raleway",
            lineHeight: "18px",
            marginBottom: "8px",
          }}
          onClick={() => setPdqs("What is your fund thesis?")}
        >
          <span
            style={{
              padding: "4px",
              borderRadius: "5px",
              backgroundColor: "rgba(167, 21, 83, 0.41)",
              fontSize: "14px",
              letterSpacing: "0.25px",
              fontWeight: 500,
              cursor: "pointer",
            }}
          >
            What is your fund thesis?
          </span>
        </div>
        <div
          style={{
            color: "white",
            fontFamily: "Raleway",
            lineHeight: "18px",
            marginBottom: "8px",
            cursor: "pointer",
          }}
          onClick={() =>
            setPdqs("How do you think about co-investment opportunities?")
          }
        >
          <span
            style={{
              padding: "4px",
              borderRadius: "5px",
              backgroundColor: "rgba(167, 21, 83, 0.41)",
              fontSize: "14px",
              letterSpacing: "0.25px",
              fontWeight: 500,
            }}
          >
            How do you think about co-investment opportunities?
          </span>
        </div>
        <div
          style={{
            color: "white",
            fontFamily: "Raleway",
            cursor: "pointer",
            lineHeight: "18px",
            marginBottom: "8px",
          }}
          onClick={() => setPdqs("What is your fund structure and fees?")}
        >
          <span
            style={{
              padding: "4px",
              borderRadius: "5px",
              backgroundColor: "rgba(167, 21, 83, 0.41)",
              fontSize: "14px",
              letterSpacing: "0.25px",
              fontWeight: 500,
            }}
          >
            What is your fund structure and fees?
          </span>
        </div>
        <div
          style={{
            color: "white",
            cursor: "pointer",
            fontFamily: "Raleway",
            lineHeight: "18px",
            marginBottom: "8px",
          }}
          onClick={() => setPdqs("Tell me more about your team.")}
        >
          <span
            style={{
              padding: "4px",
              borderRadius: "5px",
              backgroundColor: "rgba(167, 21, 83, 0.41)",
              fontSize: "14px",
              letterSpacing: "0.25px",
              fontWeight: 500,
            }}
          >
            Tell me more about your team.
          </span>
        </div>
      </Stack>
    </>
  );
};

export default Pdq;
