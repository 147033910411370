import React from "react";

import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import MainRoute from "./routes/mainRoutes";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
// Instruction use Material UI
import theme from "./MUI/theme";
import { Toaster } from "react-hot-toast";
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      {/* Provides the MUI theme */}
      <StyledEngineProvider injectFirst={true}>
        <Toaster position={"top-center"} />
        <Router>
          <MainRoute />
        </Router>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
