import React from "react";

export default function EndCallIcon() {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.56475 2.07048C3.65819 2.26616 2.84667 2.76784 2.26649 3.49147C1.68632 4.2151 1.37298 5.11625 1.37919 6.04367L1.37012 6.84022C1.37426 7.19734 1.51787 7.5387 1.77038 7.79121C2.0229 8.04372 2.36425 8.18734 2.72137 8.19132L6.12187 8.17348C6.47692 8.17046 6.81652 8.02796 7.0676 7.77688C7.31868 7.5258 7.46102 7.1862 7.4642 6.83115C7.46818 6.47403 7.61179 6.13268 7.86447 5.88016C8.11698 5.62765 8.45834 5.48404 8.81546 5.4799H14.1845C14.3623 5.47862 14.5387 5.51269 14.7032 5.5802C14.8677 5.64755 15.0172 5.74706 15.1429 5.87284C15.2687 5.99862 15.3681 6.14812 15.4356 6.31259C15.5031 6.47706 15.5372 6.65347 15.5357 6.83115C15.5389 7.1862 15.6813 7.5258 15.9323 7.77688C16.1834 8.02796 16.5232 8.17046 16.8781 8.17348H20.2786C20.6357 8.1695 20.977 8.02573 21.2296 7.77322C21.4821 7.52071 21.6258 7.17935 21.6298 6.82223L21.6209 6.02584C21.6271 5.09842 21.3136 4.19711 20.7334 3.47348C20.1534 2.75001 19.3417 2.24817 18.4352 2.05265C13.8528 1.17506 9.14487 1.18111 4.56475 2.07048Z"
        stroke="white"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5688 9.12549V16.6012"
        stroke="white"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.60278 13.6353L11.5688 16.6013L14.5346 13.6353"
        stroke="white"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
