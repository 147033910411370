import React from 'react';
import './l.css'; // Assuming you have a CSS file named loader.css for styling

const LoaderAtPro = ({ isLoading, wantIt }) => {
  return (
    <div>
      {isLoading && (
        <div className="loader-container"> {/* Added a container class */}
          <div className="loader-items"> {/* Added a items class */}
            <div className='two-letters'>
              <div className="spinner"></div>
            </div>
            <div className="loading-container"> {/* Added a container class */}
              <div className="loading loading03">
                <span>I</span>
                <span>R</span>
                <span>E</span>
                <span>N</span>
                <span>E</span>
                <span>&nbsp;</span>
                <span>I</span>
                <span>S</span>
                <span>&nbsp;</span>
                <span>T</span>
                <span>H</span>
                <span>I</span>
                <span>N</span>
                <span>K</span>
                <span>I</span>
                <span>N</span>
                <span>G</span>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoaderAtPro;
