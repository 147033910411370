import React from "react";

export default function CallIreneIcon() {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.500012 11C0.500012 4.925 5.42501 0 11.5 0C17.575 0 22.5 4.925 22.5 11C22.5 17.075 17.575 22 11.5 22C9.56401 22 7.74301 21.5 6.16201 20.62L0.900012 21.6L1.88001 16.338C0.972331 14.7055 0.497277 12.8679 0.500012 11ZM6.64701 5.647C7.30101 4.992 8.17401 4.934 8.64501 4.957C9.08001 4.977 9.42101 5.229 9.62901 5.521L10.581 6.855C10.7872 7.1439 10.8843 7.49657 10.8551 7.85032C10.8258 8.20407 10.672 8.53596 10.421 8.787L9.74701 9.46C9.97001 10.004 10.417 10.88 11.019 11.481C11.621 12.082 12.496 12.531 13.039 12.754L13.714 12.08C13.965 11.8293 14.2967 11.6757 14.6502 11.6464C15.0037 11.6171 15.3562 11.714 15.645 11.92L16.98 12.871C17.271 13.08 17.524 13.421 17.544 13.855C17.566 14.326 17.508 15.199 16.854 15.854C15.787 16.92 14.113 16.931 12.59 16.513C11.04 16.087 9.46001 15.173 8.39301 14.107C7.32801 13.041 6.41301 11.461 5.98701 9.91C5.56901 8.387 5.58001 6.713 6.64701 5.647Z"
        fill="white"
      />
    </svg>
  );
}
