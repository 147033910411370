import axios from "axios";
import CryptoJS from "crypto-js";
import toast from "react-hot-toast";

const baseURL =
  process.env.REACT_APP_NODE_ENV === "production_node_environment"
    ? process.env.REACT_APP_PRODUCTION_URL
    : process.env.REACT_APP_BACKEND_BASE_LOCAL_URL;

const Base_Url = axios.create({
  baseURL: baseURL || "https://birene.bebaai.com", // Fallback to localhost if no environment variable is set
});

Base_Url.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error("Request error:", error.message);
    toast.error("Request error");
    return Promise.reject({
      message: "Failed to send request. Please try again.",
    });
  }
);

Base_Url.interceptors.response.use(
  (response) => {
    // Decrypt the response data
    // try {
    //   if (response.data) {
    //     const decryptedData = decryptResponseData(response.data);
    //     console.log("Decrypted Data:", decryptedData);
    //     // response.data = decryptedData; // Update the response data with decrypted data
    //   }
    // } catch (error) {
    //   console.error("Decryption error:", error);
    //   throw new Error("Failed to decrypt data"); // It might be better to handle this more quietly in a production environment
    // }
    return response;
  },
  (error) => {
    console.error(
      `Error from server (status ${error?.response?.status}):`,
      error?.message
    );

    toast.error(`Error from server (status ${error?.response?.status})`);
    // Return a generic error message to prevent specific server errors from reaching the client
    return Promise.reject({
      message: "An error occurred. Please try again later.",
    });
  }
);

// Function to decrypt response data
const decryptResponseData = (encryptedData) => {
  try {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; // Securely access the encryption key
    const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (decryptionError) {
    console.error("Error decrypting data:", decryptionError);
    throw decryptionError; // This might need to be handled more gracefully depending on your error reporting strategy
  }
};

export default Base_Url;
