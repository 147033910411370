import { IconButton, Stack, Typography } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import gc from "./gc.module.css";
import { useDispatch, useSelector } from "react-redux";
import LoaderAtPro from "../../Loader/LoaderAtPro";
import voice from "./voice-svgrepo-com.png";

import ireneToon from "./ireneToon.png";
import Base_Url from "../../../api/config";
import { ireneGreetings } from "../../../Utils/IreneGreenting";
import {
  callTalkState,
  initiatGreentings,
  initiatIreneListeningMode,
  loadChats,
  loader,
  masterAITalkBack,
} from "../../../redux/action/stateAction/stateAction";
import TranscribeEcho from "./Audio/AudioStreamer";
const GlobalT = ({ setPdqs }) => {
  const chatContainerRef = useRef(null); // Ref to the chat container element
  const uid = localStorage.getItem("userId");
  const chats = useSelector(
    (state) => state?.stateReducer?.chats?.chatFolder?.chats
  );
  const greentings = useSelector((state) => state?.stateReducer?.greentings);
  const ireneListening = useSelector(
    (state) => state?.stateReducer?.ireneListening
  );
  const ireneListeningSocket = useSelector(
    (state) => state?.stateReducer?.ireneListeningSocket
  );
  const isMasterTalkBack = useSelector(
    (state) => state?.stateReducer?.isMasterTalkBack
  );

  const [ireneResponseFromTalk, setIreneResponseFromTalk] = useState("");
  const [finalTracription, setFinalTranscription] = useState("");

  console.log("🚀 ~ GlobalT ~ finalTracription:", finalTracription);

  const [startRecoOrEndReco, setStartRecoOrEndReco] = useState({
    startListeningMasterVoice: false,
    stopListeningMasterVoice: false,
  });

  const dispatch = useDispatch();
  const chatLoading = useSelector((state) => state?.stateReducer?.chatLoading);

  useEffect(() => {
    // Scroll to the bottom of the chat container whenever chats are updated
    if (chatContainerRef.current || chatLoading) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chats, chatLoading]); // Trigger effect when chats change

  function displayResponse(aiResponse) {
    const responseKeyword = "Response:";
    const responseStart = aiResponse.indexOf(responseKeyword);

    if (responseStart === -1) {
      // Handle the case where "Response:" is not found
      // console.error("Response delimiter not found in AI response:", aiResponse);
      return aiResponse;
    }

    // Trim any extra spaces or line breaks from the responseKeyword
    const trimmedResponseKeyword = responseKeyword.trim();

    const responseContent = aiResponse.substring(
      responseStart + trimmedResponseKeyword.length
    );

    // Remove unnecessary syntax like "\n\n" and leading/trailing spaces from the responseContent
    const cleanedResponseContent = responseContent.replace(/\n\n/g, "").trim();

    return cleanedResponseContent;
  }

  const fetchUserAITalks = async () => {
    console.log("Fetching user-ai chats...");
    const userAIchats = await Base_Url.get(`c/guc?userId=${uid}`);

    if (userAIchats.status === 200) {
      dispatch(loadChats(userAIchats.data));
      dispatch(loader(false));
      dispatch(callTalkState(true));
      dispatch(masterAITalkBack(false));
      setIreneResponseFromTalk("");

      dispatch(initiatIreneListeningMode(true));
      setStartRecoOrEndReco({
        startListeningMasterVoice: true,
        stopListeningMasterVoice: false,
      });
    }
  };

  useEffect(() => {
    if (ireneResponseFromTalk) {
      dispatch(masterAITalkBack(true));
      handleLingoV1(displayResponse(ireneResponseFromTalk), true);
    }
  }, [ireneResponseFromTalk]);

  const [audioBuffer, setAudioBuffer] = useState(null);
  const cleanText = (text) => {
    // Removes special characters, extra spaces, and potentially other unwanted elements.
    return text
      .replace(/[^a-zA-Z0-9 .,!?]/g, "")
      .replace(/\s+/g, " ")
      .trim();
  };

  const handleLingoV1 = async (t, masterTalk = false) => {
    const cleanedText = await cleanText(t); // Clean the text before sending
    const payload = {
      text: cleanedText,
      voiceSpeaker: 1,
      language: "en",
    };

    // for lingo model
    try {
      const res = await Base_Url.post(`c/lm`, payload);
      if (res?.status === 200 && res?.data?.lingoResponse?.task_id) {
        const taskId = res.data.lingoResponse.task_id;
        setTimeout(() => fetchLingoResponse(taskId, masterTalk), 5000);
      }
    } catch (error) {
      console.error("Error initializing audio processing:", error);
    }
  };

  const fetchLingoResponse = async (taskId) => {
    try {
      const response = await Base_Url.get(`c/lpm/${taskId}`, {
        responseType: "arraybuffer",
      });
      console.log(response, "response");
      if (response.status === 200) {
        const audioBlob = new Blob([response.data], { type: "audio/mpeg" });
        setAudioBuffer(URL.createObjectURL(audioBlob));
      } else if (response.status === 202) {
        // Assuming 202 means processing
        console.log("Processing... will retry in 5 seconds");
        setTimeout(() => fetchLingoResponse(taskId), 3000); // Retry after 5 seconds
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching audio:", error);
      const audioBlob = new Blob([error.data], { type: "audio/mpeg" });
      setAudioBuffer(URL.createObjectURL(audioBlob));
    }
  };

  useEffect(() => {
    if (!audioBuffer || greentings) return; // Return early if no audioBuffer or greetings is true

    let audio = new Audio(audioBuffer); // Initialize the Audio object

    const playAudio = async () => {
      try {
        await audio.play();
      } catch (error) {
        console.error("Failed to play audio:", error);
      }
    };

    const handleAudioEnd = () => {
      if (isMasterTalkBack) {
        fetchUserAITalks(); // Call your function when audio ends
      }

      // Safely clear the audio source
      if (audio) {
        audio.src = "";
        audio.load(); // Reload the audio to clear buffer
      }
    };

    audio.addEventListener("ended", handleAudioEnd);
    playAudio();

    return () => {
      audio.removeEventListener("ended", handleAudioEnd);
      if (audio && !audio.paused) {
        audio.pause();
      }
      audio = null; // Ensure to clear the audio reference to avoid memory leaks
    };
  }, [audioBuffer]);

  useEffect(() => {
    let audio = new Audio(ireneGreetings);

    const handleSpeechFinish = () => {
      // Perform any cleanup here
      audio.removeEventListener("ended", handleSpeechFinish);
      dispatch(initiatGreentings(false));
      dispatch(initiatIreneListeningMode(true));
      setStartRecoOrEndReco({
        startListeningMasterVoice: true,
        stopListeningMasterVoice: false,
      });
    };

    const playAudios = () => {
      try {
        audio.play().catch((error) => {
          console.error("Failed to play audio:", error);
        });
      } catch (error) {
        console.error("Failed to play audio:", error);
      }
    };

    if (greentings && AudioBuffer) {
      audio.addEventListener("canplaythrough", playAudios);
      audio.addEventListener("ended", handleSpeechFinish);

      if (audio.readyState >= 2) {
        playAudios();
      }
    }

    return () => {
      // Cleanup when the component unmounts
      if (audio) {
        audio.removeEventListener("ended", handleSpeechFinish);
        if (audio.readyState >= 2) {
          audio.pause();
        }
      }
    };
  }, [greentings]);

  function clearUserMessage(data) {
    const questionIndex = data.indexOf('Here\'s the user\'s question:');
    const contextIndex = data.indexOf('Context :');
    
    if (questionIndex === -1 || contextIndex === -1) {
      return "No user question found.";
    }
    
    const userQuestion = data.substring(questionIndex + 'Here\'s the user\'s question:'.length, contextIndex).trim();
    
    return userQuestion;
  }
  

  return (
    <Stack className={gc.cw1}>
      <Stack className={gc.cw} ref={chatContainerRef}>
        {ireneListening && (
          <Stack
            pl={"20px"}
            pb={"15px"}
            direction={"row"}
            gap={"20px"}
            alignContent={"flex-start"}
          >
            <TranscribeEcho
              startRecoOrEndReco={startRecoOrEndReco}
              setStartRecoOrEndReco={setStartRecoOrEndReco}
              autoStartTranscription={
                startRecoOrEndReco?.startListeningMasterVoice
              }
              autoEndTranscription={
                startRecoOrEndReco?.stopListeningMasterVoice
              }
              setPdqs={setPdqs}
              setIreneResponseFromTalk={setIreneResponseFromTalk}
              setFinalTranscription={setFinalTranscription} // Corrected prop name
            />

            {ireneListeningSocket && (
              <>
                {" "}
                <Stack className={gc.irl}>
                  {/* <Typography children={"IR"} /> */}
                  <img
                    src={ireneToon}
                    className={gc.ripple}
                    alt="ireneToon"
                    width={"100%"}
                    height={"auto"}
                  />
                </Stack>
                <Stack
                  direction={"column"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                >
                  <Typography
                    paddingTop={"6px"}
                    variant="body1"
                    className={gc.aiText}
                    style={{
                      color: "#a71552d5",
                    }}
                  >
                    Iren Is listening for your messages...
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
        )}

        {greentings && (
          <Stack
            pl={"20px"}
            pb={"10px"}
            direction={"row"}
            gap={"20px"}
            alignContent={"flex-start"}
          >
            <Stack className={gc.ir}>
              <Typography children={"IR"} />
            </Stack>
            <Stack
              direction={"column"}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
            >
              <Typography variant="body1" className={gc.aiText}>
                Hi this is Irene, IR Associate at VantagePoint. What can I help
                you with?
              </Typography>
              <Stack>
                <IconButton
                  onClick={() =>
                    handleLingoV1(
                      "Hi this is Irene, IR Associate at VantagePoint. What can I help you with?",
                      false
                    )
                  }
                  className={gc.b}
                >
                  <img src={voice} width="25px" height="30px" alt="" />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        )}
        {chats?.map((chat, index) => (
          <div key={index} className={gc.chatContainer}>
            <Stack direction={"column"} gap={"20px"}>
              {chat.isUserTranscription && (
                <Stack
                  direction={"row"}
                  gap={"20px"}
                  alignContent={"flex-start"}
                >
                  <Stack className={gc.you}>
                    <Typography children={"You"} />
                  </Stack>
                  <Stack>
                    <Typography variant="body1" className={gc.aiText}>
                      {clearUserMessage(chat.userMessage)}
                    </Typography>
                  </Stack>
                </Stack>
              )}

              {chat.isAITranscription && (
                <Stack
                  direction={"row"}
                  gap={"20px"}
                  alignContent={"flex-start"}
                >
                  <Stack className={gc.ir}>
                    <Typography children={"IR"} />
                  </Stack>
                  <Stack
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                  >
                    <Typography variant="body1" className={gc.aiText}>
                      {displayResponse(chat.aiResponse)}
                    </Typography>
                    <Stack>
                      <IconButton
                        onClick={() =>
                          handleLingoV1(displayResponse(chat.aiResponse), false)
                        }
                        className={gc.b}
                      >
                        <img src={voice} width="25px" height="30px" alt="" />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </div>
        ))}
      </Stack>
      {chatLoading && (
        <Stack
          pt={"10px"}
          boxShadow={"5.5px 12.5px 17px 7px #dddddd"}
          borderTop={"1px solid #a71553"}
          direction={"row"}
          gap={"20px"}
          alignContent={"flex-start"}
        >
          <LoaderAtPro isLoading={true} />
        </Stack>
      )}
    </Stack>
  );
};

export default GlobalT;
