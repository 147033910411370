import { Stack } from "@mui/material";
import React from "react";

const RightHeadUp = () => {
  return (
    <>
      <Stack>
        <div
          style={{
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: "34px",
            lineHeight: "35px",
            marginBottom: "6px",
            letterSpacing: "0.25px",
          }}
        >
          I’m <span style={{ color: "rgba(167, 21, 83, 1)" }}>Irene</span>
        </div>
        <div
          style={{
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: "34px",
            lineHeight: "35px",
            marginBottom: "6px",
            letterSpacing: "0.25px",
          }}
        >
          Your Investor Relations
        </div>
        <div
          style={{
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: "34px",
            lineHeight: "35px",
            marginBottom: "6px",
            letterSpacing: "0.25px",
          }}
        >
          Associate
        </div>
      </Stack>

      <Stack
        sx={{
          fontFamily: "Inter",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "16px",
          marginTop:"20px"
        }}
      >
        <div
          style={{
            marginBottom: "6px",
            color: "rgba(100, 100, 100, 1)",
          }}
        >
          I'm trained on what investors need from Venture Funds and have
        </div>
        <div
          style={{
            marginBottom: "6px",
            color: "rgba(100, 100, 100, 1)",
          }}
        >
          access to the VantagePoint Fund’s data room and recent updates from
        </div>
        <div
          style={{
            marginBottom: "6px",
            color: "rgba(100, 100, 100, 1)",
          }}
        >
          our portfolio cos. What do you want to know about our fund?
        </div>
      </Stack>
    </>
  );
};

export default RightHeadUp;
