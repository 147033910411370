import { IconButton, Stack, Typography } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import gc from "./gc.module.css";
import { useSelector } from "react-redux";
import LoaderAtPro from "../../Loader/LoaderAtPro";
import voice from "./voice-svgrepo-com.png";
import Base_Url from "../../../api/config";
const GlobalChat = () => {
  const chatContainerRef = useRef(null); // Ref to the chat container element

  const chats = useSelector(
    (state) => state?.stateReducer?.chats?.chatFolder?.chats
  );
  const chatLoading = useSelector((state) => state?.stateReducer?.chatLoading);

  useEffect(() => {
    // Scroll to the bottom of the chat container whenever chats are updated
    if (chatContainerRef.current || chatLoading) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chats, chatLoading]); // Trigger effect when chats change

  function displayResponse(aiResponse) {
    const responseKeyword = "Response:";
    const responseStart = aiResponse.indexOf(responseKeyword);

    if (responseStart === -1) {
      // Handle the case where "Response:" is not found
      // console.error("Response delimiter not found in AI response:", aiResponse);
      return aiResponse;
    }

    // Trim any extra spaces or line breaks from the responseKeyword
    const trimmedResponseKeyword = responseKeyword.trim();

    const responseContent = aiResponse.substring(
      responseStart + trimmedResponseKeyword.length
    );

    // Remove unnecessary syntax like "\n\n" and leading/trailing spaces from the responseContent
    const cleanedResponseContent = responseContent.replace(/\n\n/g, "").trim();

    return cleanedResponseContent;
  }
  const [audioBuffer, setAudioBuffer] = useState(null);
  const cleanText = (text) => {
    // Removes special characters, extra spaces, and potentially other unwanted elements.
    return text
      .replace(/[^a-zA-Z0-9 .,!?]/g, "")
      .replace(/\s+/g, " ")
      .trim();
  };

  const handleLingoV1 = async (t) => {
    const cleanedText = await cleanText(t); // Clean the text before sending
    const payload = {
      text: cleanedText,
      voiceSpeaker: 1,
      language: "en",
    };

    // for lingo model
    try {
      const res = await Base_Url.post(`c/lm`, payload);
      if (res?.status === 200 && res?.data?.lingoResponse?.task_id) {
        const taskId = res.data.lingoResponse.task_id;
        setTimeout(() => fetchLingoResponse(taskId), 5000);
      }
    } catch (error) {
      console.error("Error initializing audio processing:", error);
    }
  };

  const fetchLingoResponse = async (taskId) => {
    try {
      const response = await Base_Url.get(`c/lpm/${taskId}`, {
        responseType: "arraybuffer",
      });
      console.log(response, "response");
      if (response.status === 200) {
        const audioBlob = new Blob([response.data], { type: "audio/mpeg" });
        setAudioBuffer(URL.createObjectURL(audioBlob));
      } else if (response.status === 202) {
        // Assuming 202 means processing
        console.log("Processing... will retry in 5 seconds");
        setTimeout(() => fetchLingoResponse(taskId), 3000); // Retry after 5 seconds
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching audio:", error);
      const audioBlob = new Blob([error.data], { type: "audio/mpeg" });
      setAudioBuffer(URL.createObjectURL(audioBlob));
    }
  };

  useEffect(() => {
    if (AudioBuffer) {
      playAudio();
    }
  }, [audioBuffer]);

  const playAudio = () => {
    if (audioBuffer) {
      console.log("Audio buffer available:", audioBuffer);
      const audio = new Audio(audioBuffer);
      audio.play();
    } else {
      console.error("Audio buffer is not available.");
    }
  };

  
  function clearUserMessage(data) {
    const questionIndex = data.indexOf('Here\'s the user\'s question:');
    const semicolonIndex = data.indexOf(';');
    const contextIndex = data.indexOf('Context :');
    
    if (questionIndex === -1 || semicolonIndex === -1 || contextIndex === -1) {
      return data;
    }
    
    const userQuestion = data.substring(questionIndex + 'Here\'s the user\'s question:'.length, semicolonIndex).trim();
    
    return userQuestion;
  }
  
  

  return (
    <Stack className={gc.cw1}>
      <Stack className={gc.cw} ref={chatContainerRef}>
        {chats?.map((chat, index) => (
          <div key={index} className={gc.chatContainer}>
            <Stack direction={"column"} gap={"20px"}>
              <Stack direction={"row"} gap={"20px"} alignContent={"flex-start"}>
                <Stack className={gc.you}>
                  <Typography children={"You"} />
                </Stack>
                <Stack>
                  <Typography variant="body1" className={gc.aiText}>
                  {clearUserMessage(chat.userMessage)}
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction={"row"} gap={"20px"} alignContent={"flex-start"}>
                <Stack className={gc.ir}>
                  <Typography children={"IR"} />
                </Stack>
                <Stack
                  direction={"column"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                >
                  <Typography variant="body1" className={gc.aiText}>
                    {/* {chat.aiResponse} */}
                    {displayResponse(chat?.aiResponse)}
                  </Typography>
                  <Stack>
                    <IconButton
                      onClick={() =>
                        handleLingoV1(displayResponse(chat?.aiResponse))
                      }
                      className={gc.b}
                    >
                      <img src={voice} width="25px" height="30px" alt="" />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </div>
        ))}
      </Stack>
      {chatLoading && (
        <Stack
          pt={"10px"}
          boxShadow={"5.5px 12.5px 17px 7px #dddddd"}
          borderTop={"1px solid #a71553"}
          direction={"row"}
          gap={"20px"}
          alignContent={"flex-start"}
        >
          <LoaderAtPro isLoading={true} />
        </Stack>
      )}
    </Stack>
  );
};

export default GlobalChat;
