import { Stack } from "@mui/material";
import React from "react";

function CommingSoon() {
  return (
    <>
      <div style={{ fontWeight: "500", fontSize: "12px", lineHeight: "20px" }}>
        Coming soon
      </div>
      <Stack direction={"row"} gap={2} height={44}>
        {/* first card */}
        <Stack
          p={1}
          style={{ borderRadius: "5px", background: "rgba(37, 61, 209, 0.23)" }}
        >
          <Stack direction={"row"} gap={1} sx={{ height: "100%" }}>
            <img
              style={{ backgroundColor: "#253DD1", borderRadius: "3px" }}
              src={"/image/addyImg.png"}
              alt="img"
              height={28}
              width={28}
            />
            <div>
              <div
                style={{
                  fontFamily: "Raleway",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "16px",
                  letterSpacing: "0.25px",
                  textAlign: "left",
                }}
              >
                Addy
              </div>
              <div
                style={{
                  fontFamily: "Raleway",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "16px",
                  marginBottom: "6px",
                  letterSpacing: "0.25px",
                }}
              >
                Startup Growth Coach
              </div>
            </div>
          </Stack>
        </Stack>

        {/* second card */}
        <Stack
          p={1}
          style={{ borderRadius: "5px", background: "rgba(1, 198, 33, 0.16)" }}
        >
          <Stack direction={"row"} gap={1} sx={{ height: "100%" }}>
            <img
              style={{
                backgroundColor: "rgba(1, 198, 33,1)",
                borderRadius: "3px",
              }}
              src={"/image/gradyImg.png"}
              alt="img"
              height={28}
              width={28}
            />
            <div>
              <div
                style={{
                  fontFamily: "Raleway",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "16px",
                  letterSpacing: "0.25px",
                  textAlign: "left",
                }}
              >
                Grady
              </div>
              <div
                style={{
                  fontFamily: "Raleway",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "16px",
                  marginBottom: "6px",
                  letterSpacing: "0.25px",
                }}
              >
                Startup Analyst
              </div>
            </div>
          </Stack>
        </Stack>

        {/* third card */}
        <Stack
          p={1}
          style={{
            borderRadius: "5px",
            background: "rgba(239, 191, 31, 0.16)",
          }}
        >
          <Stack direction={"row"} gap={1} sx={{ height: "100%" }}>
            <img
              style={{
                backgroundColor: "rgba(239, 191, 31)",
                borderRadius: "3px",
              }}
              src={"/image/cosmoImg.png"}
              alt="img"
              height={28}
              width={28}
            />
            <div>
              <div
                style={{
                  fontFamily: "Raleway",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "16px",
                  letterSpacing: "0.25px",
                  textAlign: "left",
                }}
              >
                Cosmo
              </div>
              <div
                style={{
                  fontFamily: "Raleway",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "16px",
                  marginBottom: "6px",
                  letterSpacing: "0.25px",
                }}
              >
                Founder Chief of Staff
              </div>
            </div>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default CommingSoon;
