import {
    CALL_START,
    LOADER_STATE,
    LOAD_USER_AI_CHAT,
    CALL_TALK_START,
    INITIAT_GREENTINGS,
    IRENE_LISTENING,
    STOP_TRANSCRIPTION,MASTER_AITALK_BACK, IRENE_LISTENING_SOCKET
} from "../../reducers/actiontypes";

export const callState = (payload) => async (dispatch) => {
    dispatch({
        type: CALL_START,
        payload: payload,
    });
};

export const callTalkState = (payload) => async (dispatch) => {
    dispatch({
        type: CALL_TALK_START,
        payload: payload,
    });
};

export const forceStopTranscription = (payload) => async (dispatch) => {
    console.log("forceStopTranscription called", payload);
    dispatch({
        type: STOP_TRANSCRIPTION,
        payload: payload,
    });
};

export const masterAITalkBack = (payload) => async (dispatch) => {
    console.log("masterAITalkBack called", payload);
    dispatch({
        type: MASTER_AITALK_BACK,
        payload: payload,
    });
};

export const initiatGreentings = (payload) => async (dispatch) => {
    dispatch({
        type: INITIAT_GREENTINGS,
        payload: payload,
    });
};

export const initiatIreneListeningMode = (payload) => async (dispatch) => {
    dispatch({
        type: IRENE_LISTENING,
        payload: payload,
    });
};
export const initiatIreneListeningModeSocket = (payload) => async (dispatch) => {
    dispatch({
        type: IRENE_LISTENING_SOCKET,
        payload: payload,
    });
};

export const loader = (payload) => async (dispatch) => {
    dispatch({
        type: LOADER_STATE,
        payload: payload,
    });
};

export const loadChats = (payload) => async (dispatch) => {
    dispatch({
        type: LOAD_USER_AI_CHAT,
        payload: payload,
    });
};
