import React from "react";

export default function TextChatIcon(active) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33255 3.38609C6.48922 1.22942 9.41429 0.0178223 12.4643 0.0178223C15.5143 0.0178223 18.4394 1.22942 20.596 3.38609C22.7527 5.54276 23.9643 8.46783 23.9643 11.5178C23.9643 14.5678 22.7527 17.4929 20.596 19.6495C18.4394 21.8062 15.5143 23.0178 12.4643 23.0178C10.8633 23.0178 9.29657 22.6839 7.8575 22.0548L0 23.9821L1.9273 16.1246C1.29816 14.6855 0.964272 13.1188 0.964272 11.5178C0.964272 8.46783 2.17588 5.54276 4.33255 3.38609ZM11.9644 12.0178V8.01782H7.96436V11.0178H9.96436V12.0178C9.96436 12.5701 9.51665 13.0178 8.96436 13.0178V15.0178C10.6213 15.0178 11.9644 13.6747 11.9644 12.0178ZM16.9644 12.0178V8.01782H12.9644V11.0178H14.9644V12.0178C14.9644 12.5701 14.5167 13.0178 13.9644 13.0178V15.0178C15.6213 15.0178 16.9644 13.6747 16.9644 12.0178Z"
        fill={active ? "#A71553" : "white"}
      />
    </svg>
  );
}
