import React from "react";

export default function ChatIcon(active) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.1737e-05 11C1.1737e-05 4.925 4.92501 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C9.06401 22 7.24301 21.5 5.66201 20.62L0.400012 21.6L1.38001 16.338C0.472331 14.7055 -0.00272256 12.8679 1.1737e-05 11ZM6.14701 5.647C6.80101 4.992 7.67401 4.934 8.14501 4.957C8.58001 4.977 8.92101 5.229 9.12901 5.521L10.081 6.855C10.2872 7.1439 10.3843 7.49657 10.3551 7.85032C10.3258 8.20407 10.172 8.53596 9.92101 8.787L9.24701 9.46C9.47001 10.004 9.91701 10.88 10.519 11.481C11.121 12.082 11.996 12.531 12.539 12.754L13.214 12.08C13.465 11.8293 13.7967 11.6757 14.1502 11.6464C14.5037 11.6171 14.8562 11.714 15.145 11.92L16.48 12.871C16.771 13.08 17.024 13.421 17.044 13.855C17.066 14.326 17.008 15.199 16.354 15.854C15.287 16.92 13.613 16.931 12.09 16.513C10.54 16.087 8.96001 15.173 7.89301 14.107C6.82801 13.041 5.91301 11.461 5.48701 9.91C5.06901 8.387 5.08001 6.713 6.14701 5.647Z"
        fill="#A71553"
      />
    </svg>
  );
}
